import React from 'react';
import { graphql, Link } from 'gatsby';
import { merge, Box } from 'theme-ui';
import Seo from '@solid-ui-components/Seo';
import Layout from '@solid-ui-layout/Layout';
import Header from '@solid-ui-blocks/Header/Block01';
import Footer from '@solid-ui-blocks/Footer/Block01';
import flowUITheme from '@elegantstack/flow-ui-theme/src/theme';
import { normalizeBlockContentNodes } from '@blocks-helpers';
import theme from '../../pages/new-landing/_theme';
import SupportWrapper from './components/SupportWrapper';
import SupportListView from './components/SupportListView';
import ConvertTextToComponent from '../../../content/components/ConvertTextToComponent';

const styles = {
  previewBox: {
    backgroundColor: 'white',
    boxShadow: '0px 0px 19px -7px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    padding: '30px 70px',
    marginBottom: 14,
    transition: '0.4s',
    border: 'none',
    '&:hover': {
      backgroundColor: '#eee',
      cursor: 'pointer',
    },
  },
};

const SupportCategory = (props) => {
  const {
    currentCategory,
    allBlockContent,
    allFaqCategories,
    relatedFaqQuestions,
    allFaqQuestions: { nodes: allFaqQuestions },
  } = props.data;
  const content = normalizeBlockContentNodes(allBlockContent?.nodes);

  return (
    <Layout theme={merge(theme, { ...flowUITheme, colors: {} })} {...props}>
      <Seo title={`Support - ${currentCategory.name}`} />
      <Header content={content['header']} />
      <SupportWrapper
        title={content['support-index']?.kicker.text}
        placeholder={content['faq-categories']?.description.textGroup[0]}
        searchData={allFaqQuestions}
        footer={
          <ConvertTextToComponent
            content={content['support-index'].description.detailedTexts}
            marginX='4'
            marginY='2'
          />
        }
      >
        <SupportListView
          links={[
            { title: 'Home', link: '/support' },
            {
              title: currentCategory.name,
              link: `/support/${currentCategory.slug}`,
            },
          ]}
          list={{
            title: content['support-category'].text_1.text,
            links: allFaqCategories.nodes.map(({ name, slug }) => ({
              name,
              link: `/support/${slug}`,
              active: currentCategory.slug === slug,
            })),
          }}
        >
          {relatedFaqQuestions.nodes.map(({ title, slug, category }) => (
            <Link to={`/support/${category.slug}/${slug}`}>
              <Box sx={styles.previewBox}>{title}</Box>
            </Link>
          ))}
        </SupportListView>
      </SupportWrapper>
      <Footer content={content['footer']} />
    </Layout>
  );
};

export const query = graphql`
  query faqCategoriesQuery($id: String, $blocksPaths: [String]!) {
    allBlockContent: allBlockContent(
      filter: {
        page: { in: $blocksPaths }
        identifier: {
          in: [
            "header"
            "footer"
            "support-index"
            "support-category"
            "faq-categories"
          ]
        }
      }
    ) {
      nodes {
        ...BlockContent
      }
    }
    currentCategory: faqCategory(id: { eq: $id }) {
      name
      slug
    }
    relatedFaqQuestions: allFaqQuestion(
      filter: { category: { id: { eq: $id } } }
      sort: { fields: index, order: ASC }
    ) {
      nodes {
        title
        slug
        category {
          slug
        }
      }
    }
    allFaqCategories: allFaqCategory(sort: { order: ASC, fields: index }) {
      nodes {
        name
        slug
      }
    }
    allFaqQuestions: allFaqQuestion(sort: { fields: index, order: ASC }) {
      nodes {
        title
        slug
        category {
          name
          slug
        }
      }
    }
  }
`;

export default SupportCategory;
